import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import reducer from '../reducers/index';
import rootReducer from '../reducers/index';

export const setupStore = (preloadedState?: Partial<RootState>) => {
  return configureStore({
    reducer,
    preloadedState: preloadedState as any,
  });
};

export const store = setupStore();
export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
