import * as Sentry from '@sentry/react';
import React from 'react';
import { filterValues } from './filterConst';
import { subscribe, unsubscribe } from 'util/customEvents';
import { LOG_SENTRY_ERROR } from 'util/customEvents/eventContracts';
import { useEnableSentry } from './hooks';

export const InitSentry = () => {
  const { enableSentry } = useEnableSentry();

  const initSentry = () => {
    const beforeSend = (event: Sentry.ErrorEvent) => {
      if (event?.exception && event.exception?.values) {
        const exception = event.exception.values[0];
        if (filterValues.includes(String(exception.value))) {
          // Drop this event and don't send it to Sentry
          return null;
        }
      }
      return event;
    };
    if (!Sentry.isInitialized())
      Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        integrations: [
          Sentry.browserTracingIntegration(),
          Sentry.replayIntegration(),
          Sentry.browserProfilingIntegration(),
        ],
        release: `wp-app@${process.env.REACT_APP_VERSION}`,
        environment: process.env.NODE_ENV,
        tracesSampleRate: 1.0,
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        beforeSend,
        profilesSampleRate: 1.0,
      });
  };

  React.useEffect(() => {
    if (enableSentry) initSentry();
  }, [enableSentry]);

  const logErrorInSentry = (error: any) => {
    if (Sentry.isInitialized()) {
      const id = Sentry.captureException(error?.detail?.actualError, {
        extra: {
          parameters: {
            request: JSON.stringify(error?.detail?.request),
            urlEndpoint: JSON.stringify(error?.detail?.url),
            response: error?.detail?.response,
            normalized: error?.detail?.normalized,
          },
        },
      });
      console.log('Logged error to Sentry with ID:', id);
    }
  };

  React.useEffect(() => {
    if (enableSentry)
      subscribe({
        eventName: LOG_SENTRY_ERROR,
        listener: logErrorInSentry,
      });
    return () => {
      if (enableSentry) unsubscribe({ eventName: LOG_SENTRY_ERROR, listener: logErrorInSentry });
    };
  }, [enableSentry]);

  return null;
};
