import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store/store';
import { ICompany, IConfig } from 'services/ui-config-access/uiConfigAccess.contracts';
import { SetDontShowMeFlagRequest, SetDontShowMeFlagResponse } from 'services/person-access/personAccess.contracts';
import { SetDontShowMeFlag } from 'services/person-access/personAccess.service';
import { SubscriptionPeriodInterface } from 'shared/services/payroll/uiConfigAccess';
import { FeatureTypeEnum } from '@wagepoint/ui-shared-library/shared-system/services/payroll/models/access/uiConfigAccess';
import PersonSettingsAccessService, {
  GetMigrationPreferenceRequestInterface,
  GetMigrationPreferenceResponseInterface,
  SetMigrationPreferenceRequestInterface,
  SetMigrationPreferenceResponseInterface,
} from 'services/appCore/models/access/personSettingAccess';

const initialState: IConfig = {
  config: {},
  migration: {
    dontshowFlag: {
      loading: false,
      value: null,
    },
    signatoryName: { name: null },
  },
  selectedCompany: {},
  loadingDontshowEnum: false,
  reload: false,
  permissionConfig: {},
  showLoader: false,
  isCompanySwitched: false,
  isPartnerInviteModalConfirmed: false,
  showMigrationPartnerAccessModal: false,
  reports: {
    annualReport: {
      employeeHadYtd: false,
      isLoading: false,
    },
  },
};

export const setDontShowMeFlag = createAsyncThunk(
  'config/setDontShowMeFlag',
  async (request: SetDontShowMeFlagRequest) => {
    const response: SetDontShowMeFlagResponse = await SetDontShowMeFlag(request);
    return response;
  }
);
export const getDontShowMeFlagMigration = createAsyncThunk(
  'config/getDontShowFlag',
  async (request: GetMigrationPreferenceRequestInterface) => {
    const response: GetMigrationPreferenceResponseInterface = await PersonSettingsAccessService.getMigrationPreference(
      request
    );
    return response;
  }
);

export const setDontShowMeFlagMigration = createAsyncThunk(
  'config/setDontShowMeFlagMigration',
  async (request: SetMigrationPreferenceRequestInterface) => {
    const response: SetMigrationPreferenceResponseInterface = await PersonSettingsAccessService.setMigrationPreference(
      request
    );
    return response;
  }
);

export const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    updateConfig: (state, action: PayloadAction<IConfig>) => {
      state.config = action.payload.config;
      state.selectedCompany = action.payload.selectedCompany;
      state.migration = action.payload.migration;
      state.reload = false;
      state.showLoader = false;
    },

    updateSubscriptionPeriod: (
      state,
      action: PayloadAction<{ subscriptionPeriod: SubscriptionPeriodInterface; userNeedsToOptInPlan: boolean }>
    ) => {
      state.config.userNeedsToOptInPlan = action.payload.userNeedsToOptInPlan;
      state.config.subscriptionPeriod = action.payload.subscriptionPeriod;
    },

    updateSelectedCompany: (state, action: PayloadAction<ICompany>) => {
      state.selectedCompany = action.payload;
    },

    reloadConfig: (state, action: PayloadAction<boolean>) => {
      state.reload = action.payload;
    },

    updateCompanySwitched: (state, action: PayloadAction<boolean>) => {
      state.isCompanySwitched = action.payload;
    },

    showLoaderConfig: (state, action: PayloadAction<boolean>) => {
      state.showLoader = action.payload;
    },

    updatePermissionConfig: (state, action: any) => {
      state.permissionConfig = action.payload;
    },

    updatePartnerMigrationAcessModal: (state, action: PayloadAction<boolean>) => {
      state.showMigrationPartnerAccessModal = action.payload;
    },
    updateShowMigrationDashboard: (state, action: PayloadAction<boolean>) => {
      state.config.showMigrationDashboard = action.payload;
    },

    updateCompanyStatusType: (state, action: any) => {
      state.config = { ...state.config, companyStatusType: action.payload };
    },
    updatePartnerCompanyId: (state, action: any) => {
      state.config = { ...state.config, partnerCompanyId: action.payload };
    },
    update2StepApproval(state, action: PayloadAction<boolean>) {
      state.config.hasTwoStepApprovalOn = action.payload;
    },
    updateAutoAdminAssignment(state, action: PayloadAction<boolean>) {
      state.config.isAutoAssignAdminOn = action.payload;
    },
    hidePartnerInvite(state) {
      state.isPartnerInviteModalConfirmed = true;
    },
    setAnnualReportsData(state, action: PayloadAction<{ isLoading: boolean; employeeHadYtd: boolean }>) {
      if (state.reports) state.reports.annualReport = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setDontShowMeFlag.pending, (state) => {
      state.loadingDontshowEnum = true;
    }),
      builder.addCase(setDontShowMeFlag.fulfilled, (state, action) => {
        state.config.dontShowMeFlags = action.payload.dontShowMeFlags;
        state.loadingDontshowEnum = false;
      }),
      builder.addCase(setDontShowMeFlag.rejected, (state) => {
        state.loadingDontshowEnum = false;
      });

    builder.addCase(setDontShowMeFlagMigration.pending, (state) => {
      state.migration.dontshowFlag.loading = true;
    }),
      builder.addCase(setDontShowMeFlagMigration.fulfilled, (state, action) => {
        state.migration.dontshowFlag.loading = false;
        state.migration.dontshowFlag.value = action.payload.dontShowMeFlags;
      }),
      builder.addCase(setDontShowMeFlagMigration.rejected, (state) => {
        state.migration.dontshowFlag.loading = false;
      });

    builder.addCase(getDontShowMeFlagMigration.pending, (state) => {
      state.migration.dontshowFlag.loading = true;
    }),
      builder.addCase(getDontShowMeFlagMigration.fulfilled, (state, action) => {
        state.migration.dontshowFlag.loading = false;
        state.migration.dontshowFlag.value = action.payload.dontShowMeFlags;
      }),
      builder.addCase(getDontShowMeFlagMigration.rejected, (state) => {
        state.migration.dontshowFlag.loading = false;
      });
  },
});

export const {
  updateConfig,
  updateSelectedCompany,
  reloadConfig,
  showLoaderConfig,
  updatePermissionConfig,
  updateCompanySwitched,
  updateCompanyStatusType,
  updateSubscriptionPeriod,
  updatePartnerCompanyId,
  update2StepApproval,
  hidePartnerInvite,
  updateAutoAdminAssignment,
  updatePartnerMigrationAcessModal,
  updateShowMigrationDashboard,
  setAnnualReportsData,
} = configSlice.actions;
export const configReducer = configSlice.reducer;

export const selectConfig = (state: RootState) => state.configReducer;

export const selectCompanyId = createSelector(selectConfig, ({ selectedCompany }) => selectedCompany.companyId ?? 0);

export const selectCompany = createSelector(selectConfig, ({ selectedCompany }) => selectedCompany);

export const selectPersonId = createSelector(selectConfig, ({ config }) => config.personId ?? 0);

export const selectCountry = createSelector(selectConfig, ({ config }) => config.country);
export const selectCountryId = createSelector(selectConfig, ({ config }) => config?.country?.id ?? 0);
export const selectDontShowMeFlag = createSelector(selectConfig, ({ config }) => config?.dontShowMeFlags);

export const selectUserId = createSelector(selectConfig, ({ config }) => config.userId ?? 0);

export const selectSecurityRoleType = createSelector(selectConfig, ({ config }) => config.securityRoleType);
export const selectIsEmployee = createSelector(selectConfig, ({ config }) => config.isEmployee);
export const selectIsContractor = createSelector(selectConfig, ({ config }) => config.isContractor);

export const selectCultureType = createSelector(selectConfig, ({ config }) => config.cultureType);

export const selectCompanyCode = createSelector(selectConfig, ({ config }) => config.companyCode);
export const selectTenantId = createSelector(selectConfig, ({ config }) => config.tenantId);

export const selectEmailAddress = createSelector(selectConfig, ({ config }) => config.emailAddress);

export const selectUserFirstName = createSelector(selectConfig, ({ config }) => config.firstName);
export const selectUserLastName = createSelector(selectConfig, ({ config }) => config.lastName);
export const selectSubscriptionIsTrial = createSelector(selectConfig, ({ config }) => config.isTrail);
export const selectSubscriptionPlanName = createSelector(selectConfig, ({ config }) => config.subscriptionPlanName);
export const selectCompanyType = createSelector(selectConfig, ({ config }) => config.companyType);

export const selectMigrationStatusCompanyType = createSelector(selectConfig, ({ config }) => config.actualCompanyType);
export const selectMigrationStatusIsMigratedCompany = createSelector(
  selectConfig,
  ({ config }) => config.isMigratedCompany
);
export const selectMigrationStatus = createSelector(selectConfig, ({ config }) => ({
  companyType: config.actualCompanyType,
  isMigratedCompany: config.isMigratedCompany,
}));
export const selectMigrationDontshowFlag = createSelector(
  selectConfig,
  ({ migration: { dontshowFlag } }) => dontshowFlag
);
export const selectMigrationSignatoryName = createSelector(
  selectConfig,
  ({ migration: { signatoryName } }) => signatoryName.name
);

export const selectTimeZonePreference = createSelector(selectConfig, ({ config }) => config.timeZoneType);

export const selectCompanyName = createSelector(selectConfig, ({ selectedCompany }) => selectedCompany.name);

export const isPartnerInviteModalConfirmed = (state: RootState) => state.configReducer.isPartnerInviteModalConfirmed;

export const selectSubscriptionPeriod = createSelector(selectConfig, ({ config }) => config.subscriptionPeriod);
export const selectSubscriptionPeriodByFeatureType = (featureType: FeatureTypeEnum) =>
  createSelector(
    selectConfig,
    ({ config }) =>
      config.subscriptionPeriod?.featureList?.some((feature) => feature.featureType === featureType) ?? false
  );
export const selectCompanyStatusType = createSelector(selectConfig, ({ config }) => config.companyStatusType);

export const selectSubscriptionCanCreateNewPayGroup = createSelector(
  selectSubscriptionPeriod,
  (state) => state?.canCreateNewPayGroup
);
export const selectAnnualReportsData = createSelector(selectConfig, (state) => state?.reports?.annualReport);

export const selectUserNeedsToOptInPlan = createSelector(selectConfig, ({ config }) => config.userNeedsToOptInPlan);

export const selectSubscriptionCanRunPayroll = createSelector(
  selectSubscriptionPeriod,
  (state) => state?.canRunPayroll ?? false
);

export const selectConnectedPartner = createSelector(selectConfig, ({ config }) => ({
  partnerName: config.partnerCompanyName,
  partnerId: config.partnerCompanyId,
}));
export const selectConnectedPartnerName = createSelector(selectConfig, ({ config }) => config.partnerCompanyName);
export const selectConnectedPartnerId = createSelector(selectConfig, ({ config }) => config.partnerCompanyId);
export const selectPartnerHandlingUpgradeStatus = createSelector(
  selectConfig,
  ({ config }) => config?.isPartnerHandlingUpgrade
);

export const selectAccountOwnerEmailVerified = createSelector(
  selectConfig,
  ({ config }) => config.isAccountOwnerEmailVerified
);

export const selectTwoStepApprovalState = createSelector(selectConfig, ({ config }) => config.hasTwoStepApprovalOn);

export const selectReloadState = (state: RootState) => state.configReducer.reload;

export const selectCompanyRelatedStates = createSelector(
  selectConfig,
  ({ config: { companySignupDate, companyCode, companyName } }) => ({
    companySignupDate,
    companyCode,
    companyName,
  })
);
export const selectIsMigratedCompany = createSelector(selectConfig, ({ config: { showMigrationDashboard } }) => ({
  showMigrationDashboard,
}));

export const selectAccountOwnerName = createSelector(selectConfig, ({ config: { accountOwnerName } }) => ({
  accountOwnerName,
}));
export const selectShowMigrationDahsboard = createSelector(
  selectConfig,
  ({ config: { showMigrationDashboard } }) => showMigrationDashboard
);

export const selectIsAutoAssignOn = createSelector(selectConfig, ({ config: { isAutoAssignAdminOn } }) => ({
  isAutoAssignAdminOn,
}));
export const selectPartnerConnected = createSelector(selectConfig, ({ config: { isPartnerConnected } }) => ({
  isPartnerConnected,
}));

export const selectIsDontShowLoading = createSelector(selectConfig, ({ loadingDontshowEnum }) => ({
  loadingDontshowEnum,
}));
export const selectLoadingDontshowEnum = createSelector(selectConfig, ({ loadingDontshowEnum }) => loadingDontshowEnum);

export const selectPermissionConfig = createSelector(selectConfig, ({ permissionConfig }) => permissionConfig);
export const selectMigrationPartnerModalVisbility = createSelector(
  selectConfig,
  ({ showMigrationPartnerAccessModal }) => showMigrationPartnerAccessModal
);
