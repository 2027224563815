import { useEffect, useState } from 'react';
import { IAuthUserConfig } from 'services/ui-config-access/uiConfigAccess.contracts';
import i18next from 'i18n/i18n';
import { useAppDispatch } from 'redux/hooks/hook';
import { getDontShowMeFlagMigration, updateConfig, updatePermissionConfig } from 'redux/slice/configSlice';
import { sucurePortalCheckNew } from './sucurePortalCheck';
import { useFromAlfredUser } from 'shared-components/useFromAlfredUser/useFromAlfredUser';
import { SecurityRoleTypeEnum } from 'services/shared/securityRoleTypeEnum';
import {
  CultureTypeEnum,
  GetConfigurationResponseInterface,
  IAuthUserConfiguration,
  UserDetail,
} from 'shared/services/payroll/uiConfigAccess';
import { useGetConfigurationHook } from 'applications/app/core-service-hooks/useGetConfigurationHook';
import { useLoaders } from 'redux/hooks/useLoaders';
import { useGetInitialStateHook } from 'applications/app/core-service-hooks/useGetInitialStateHook';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { MigrationTypeEnum } from 'shared/services/appCore/schema';
import { useEnableMigrationDontshowFlagAction } from 'pages/migration/safetyHooks';
import { generateFullName } from 'util/utility';
import { setUser as sentrySetUser } from '@sentry/react';

export interface GetTmpConfigResponseInterface extends GetConfigurationResponseInterface {
  userHasChangedPlan: boolean;
}

export function useAuthProvider() {
  const localLanguage = localStorage.getItem('language');
  const [user, setUser] = useState<Partial<IAuthUserConfiguration>>();
  const dispatch = useAppDispatch();
  const { userIdFromAlfed, overrideCompanyType, companyName, companyCode } = useFromAlfredUser();
  const { showLoader, hideLoader } = useLoaders();
  const { data, isSuccess } = useGetConfigurationHook();
  const { enableCondition } = useEnableMigrationDontshowFlagAction();

  const ldClient = useLDClient();

  const updateUserContextKindLaunchDarkly = () => {
    ldClient?.identify({
      kind: 'multi',
      user: {
        key: user?.userDetail?.emailAddress,
        name: user?.userDetail?.firstName,
        email: user?.userDetail?.emailAddress,
        tenantId: user?.userDetail?.tenantId,
      },
    });
  };

  useEffect(() => {
    if (user) updateUserContextKindLaunchDarkly();
  }, [user]);
  /* --------------------------------------------------------------- */
  // this code snippet is used to getInitial state API call, which checks if the onboarding for this user is completed or not.
  const { isLoading } = useGetInitialStateHook(); // this hook only works if company ID is saved to config slice by getConfiguration API call.
  useEffect(() => {
    if (isLoading) showLoader();
    else hideLoader();
  }, [isLoading]);
  /* --------------------------------------------------------------- */

  // call 1
  const afterGetConfiguration = () => {
    if (data) {
      showLoader();
      const { userDetail } = data;
      if (data.isMigratedCompany && enableCondition)
        callMigrationActions({
          personId: userDetail.personId,
          companyId: userIdFromAlfed || userDetail?.tenantId || 0,
        });
      // to set the language selected by the user
      setUserDefaultsLanguage(userDetail);
      /**
       * sucurePortalCheckNew : Functions to perform application redirections employee portal / alfred
       * generateMenu: This is a callback to proceed with menu creation & permission.
       */
      sucurePortalCheckNew(userDetail, userIdFromAlfed || userDetail?.tenantId, generateMenu); // this would invoke the generateMenu function if the condition satisfies
    }
  };

  const callMigrationActions = (params: { personId: number; companyId: number }) => {
    dispatch(getDontShowMeFlagMigration({ ...params }));
  };
  useEffect(() => {
    // when the getConfiguration API is sucessfull then we would invoke this function which would process the data
    if (isSuccess) afterGetConfiguration();
  }, [isSuccess, data]);

  // call 2
  const generateMenu = async () => {
    /*
      Logic - to create an object - which contains url as the key and value as an object, with isViewOnly key and value.
      This structure will be updated in redux store to avoid mappings each time.
      */
    try {
      if (data) {
        const { menuList } = data;
        let menuItemsObject = {};
        const generateMenuList = (list: any) => {
          if (list && list.url) menuItemsObject = { [list?.url]: { isViewOnly: list?.isViewOnly }, ...menuItemsObject };
          list.forEach((submenuItems: any) => {
            menuItemsObject = {
              [submenuItems?.url]: {
                isViewOnly: submenuItems?.isViewOnly,
                isDisabled: submenuItems?.isDisabled,
                isVisible: submenuItems?.isVisible,
              },
              ...menuItemsObject,
            };
            if (submenuItems.menuItemList && submenuItems.menuItemList.length > 0)
              generateMenuList(submenuItems.menuItemList);
            if (submenuItems.subMenuList && submenuItems.subMenuList.length > 0)
              generateMenuList(submenuItems.subMenuList);
          });
        };
        generateMenuList(menuList);
        // this dispatch would setup all the menuItems & their related permissions
        await dispatch(updatePermissionConfig(menuItemsObject));

        await generateAndSaveInformationToRedux(); // this would indeed save the companyId into the redux which will trigger the getInitialStatehook defined above
        setSentryUser(data.userDetail);
        hideLoader();
      }
    } catch {
      hideLoader();
    }
  };

  /**
   * this will save all the information into the configSlice and the whole app make use of this information to proceed further.
   * @returns none
   */
  const generateAndSaveInformationToRedux = async () => {
    if (data) {
      const {
        companyStatusType,
        menuList,
        userDetail,
        logoId,
        isPartnerFirm,
        useLogoAsFavicon,
        hasAssociatedCompanys,
        wagepointCompanySettingsList,
        subscriptionPeriod,
        userNeedsToOptInPlan,
        hasTwoStepApprovalOn,
        partnerCompanyName,
        partnerCompanyId,
        companySignupDate,
        showMigrationDashboard,
        isMigratedCompany,
        companyType,
        isPartnerHandlingUpgrade,
        isAutoAssignAdminOn,
        migrationInfo,
      } = data;

      setUser({
        companyStatusType,
        menuList,
        userDetail,
      });

      return await dispatch(
        updateConfig({
          selectedCompany: {
            companyId: userIdFromAlfed || userDetail?.tenantId,
            name: userIdFromAlfed ? companyName : userDetail?.companyName || '',
            logoId,
          },
          migration: {
            dontshowFlag: {
              loading: false,
              value: null,
            },
            signatoryName: {
              name: generateFullName({
                fn: migrationInfo?.signatoryFirstName || '',
                ln: migrationInfo?.signatoryLastName || '',
              }),
            },
          },
          config: {
            cultureType: userDetail?.cultureType,
            country: userDetail?.country,
            menuList,
            isPartnerFirm,
            showMigrationDashboard,
            emailAddress: userDetail?.emailAddress,
            tenantId: userIdFromAlfed || userDetail?.tenantId,
            companyName: userIdFromAlfed ? companyName : userDetail?.companyName,
            companyCode: userIdFromAlfed ? companyCode : userDetail?.companyCode,
            companyType: userIdFromAlfed ? overrideCompanyType : userDetail?.companyType,
            actualCompanyType: companyType,
            isMigratedCompany,
            dontShowMeFlags: userDetail?.dontShowMeFlags,
            personId: userDetail?.personId,
            userId: userDetail?.userId,
            firstName: userDetail?.firstName,
            isAutoAssignAdminOn: isAutoAssignAdminOn,
            isPartnerConnected: migrationInfo?.migrationType === MigrationTypeEnum.connect || false,
            lastName: userDetail?.lastName,
            securityRoleType: (userIdFromAlfed
              ? SecurityRoleTypeEnum.AccountOwner
              : userDetail?.securityRoleType) as any,
            isEmployee: userDetail?.isEmployee,
            isContractor: userDetail?.isContractor,
            logoId,
            isTrail: subscriptionPeriod?.isTrial,
            subscriptionPlanName: subscriptionPeriod?.subscriptionPlanName,
            useLogoAsFavicon,
            hasAssociatedCompanys,
            companyStatusType,
            companySignupDate,
            wagepointCompanySettingsList,
            timeZoneType: userDetail?.timeZoneType as any,
            subscriptionPeriod: subscriptionPeriod || {},
            userNeedsToOptInPlan: userNeedsToOptInPlan,
            hasTwoStepApprovalOn,
            partnerCompanyId: partnerCompanyId,
            partnerCompanyName: partnerCompanyName,
            isAccountOwnerEmailVerified: userDetail?.isAccountOwnerEmailVerified,
            accountOwnerName: userDetail?.accountOwnerName,
            isPartnerHandlingUpgrade: !!isPartnerHandlingUpgrade,
          },
        })
      );
    }
  };

  const setUserDefaultsLanguage = (userDetail: UserDetail) => {
    if (localLanguage?.length) i18next.changeLanguage(localLanguage);
    else
      switch (userDetail?.cultureType) {
        case CultureTypeEnum.englishUs:
          i18next.changeLanguage('en-CA');
          break;
        case CultureTypeEnum.englishCa:
          i18next.changeLanguage('en-CA');
          break;
        case CultureTypeEnum.frenchCa:
          i18next.changeLanguage('fr-FR');
          break;
      }
  };

  // Providing User context for Sentry
  const setSentryUser = (userDetail: UserDetail) => {
    if (userDetail) {
      sentrySetUser({
        id: userDetail?.userId,
        username: userDetail?.firstName + ' ' + data?.userDetail?.lastName,
        companyName: userIdFromAlfed ? companyName : userDetail?.companyName,
        companyCode: userIdFromAlfed ? companyCode : userDetail?.companyCode,
        email: userDetail?.emailAddress,
      });
    }
  };

  return {
    user,
  };
}

export type AuthContextState = {
  user: Partial<IAuthUserConfig> | undefined;
};
