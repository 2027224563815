import { useFeatureFlag } from 'LDfeatureFlag';
import { FEATURE_FLAG_KEYS } from 'LDfeatureFlag/constants';
import { publish } from 'util/customEvents';
import { LOG_SENTRY_ERROR } from 'util/customEvents/eventContracts';

export const useEnableSentry = () => {
  const { feature } = useFeatureFlag();
  return { enableSentry: feature(FEATURE_FLAG_KEYS.EnableSentry) };
};
export const useSentryErrorNormalizer = () => {
  const { feature } = useFeatureFlag();
  return { enable: feature(FEATURE_FLAG_KEYS.NormalizeSentryErrors) };
};

export const initSentryError = ({
  error,
  request,
  url,
  status,
}: {
  status: number;
  error: string | Error;
  request: string;
  url: string;
}) => {
  let normalizedError: string | undefined = undefined; // Ensure it's a string

  try {
    if (error instanceof Error) {
      // Handle JavaScript Error instances
      normalizedError = JSON.stringify({
        statusCode: status,
        name: error.name,
        message: error.message,
      });
    } else if (typeof error === 'object' && error !== null) {
      // Handle plain objects (non-Error)
      const statusCode = (error as any)?.response?.status;
      if (statusCode) normalizedError = `status code: ${statusCode}` + JSON.stringify(error);
    } else if (typeof error === 'string') {
      // Handle string errors
      normalizedError = error;
    } else {
      // Handle undefined, null, or unexpected types
      normalizedError = `An unknown error occurred for request: ${request}`;
    }
  } catch (normalizationError: unknown) {
    // Fallback if normalization itself fails
    normalizedError = `Failed to process the error: ${
      normalizationError instanceof Error ? normalizationError.message : 'Unknown error during normalization'
    }`;
  }

  // Example of logging the normalized error, replace this with your Sentry logic
  if (normalizedError)
    publish({ eventName: LOG_SENTRY_ERROR, data: { actualError: error, normalized: normalizedError, url, request } });
};
