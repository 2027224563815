import { IValidationResults } from 'services/shared/validationResults.contracts';
import { ValidationResultsInterface } from './yearEndAccess';
import { ExplicitFormTypeEnum } from 'services/appCore/models/schema/Schema';

export enum SubmissionStatusTypeEnum {
  NotDefined = 0,
  NotSpecified = 1,
  Electronic = 2,
  Manual = 3,
}

export enum PackageTypeEnum {
  NotDefined,
  NotSpecified,
  Package,
  PackageAmendment,
}

export enum PackageStatusTypeEnum {
  NotDefined,
  NotSpecified,
  Draft,
  FilingInProgress,
  Finalized,
  SubmissionInProgress,
  Filed,
  Generated,
  Deleted,
  Submitted,
  FailedToProcess,
  FileGenerationInProgress,
}

export enum ViewTypeEnum {
  NotDefined = 0,
  NotSpecified = 1,
  Single = 2,
  Grid = 3,
}

export enum FormIdEnum {
  T4 = 2,
  T4A = 3,
  RL1 = 4,
  W2 = 5,
  W3 = 6,
  Ten99 = 7,
  RoeEn = 8,
  BankAgreementPreAuth = 9,
  BankAgreementNatPay = 10,
  RoeRegistrationAddendumEn = 11,
  RoeRegistrationAddendumFr = 12,
  T4Summary = 13,
  T4ASummary = 14,
  RL1Summary = 15,
}

export enum EmployeeTypeEnum {
  Employee = 1,
  Contractor = 2,
}

export enum LastCompletedStepTypeEnum {
  notSpecified = 0,
  t4 = 1,
  t4A = 2,
  rL1 = 3,
  rL1Summary = 4,
  packageSummary = 5,
}

export type ResponseValidationResults = { validationResults: IValidationResults };
export type ResponseWithErrors<T> = T & ResponseValidationResults;

export type YearEndPackage = {
  id: number;
  name: string;
  confirmationCode?: string;
  confirmedAccuracy: boolean;
  packageType: PackageTypeEnum;
  packageStatusType: PackageStatusTypeEnum;
  submissionDate: string;
  submissionStatusType: SubmissionStatusTypeEnum;
  viewType: ViewTypeEnum;
  lastCompletedStepType: LastCompletedStepTypeEnum;
  filedDate?: string;
  formTypeList?: ExplicitFormTypeEnum[];
  defaultContactName?: string;
  defaultContactNumber?: string;
  defaultContactAreaCode?: string;
  defaultContactCountryCode?: string;
};

type TaxFormBase = {
  formId: FormIdEnum;
  formName: string;
  yearEndTaxFormId: number;
  employeeId: number;
  employeeName: string;
  stateId: number;
  stateCode: string;
};

export type EmployeeTaxForm = TaxFormBase & {
  allowDelete: boolean;
  pdfDocumentId?: number;
  isSelected: boolean;
  employmentType: EmployeeTypeEnum;
  t4aExempted: boolean;
  lastAmendmentDate: string;
  formBoxList: [];
};

export type EmployeeTaxFormMatrixRequest = {
  formId: FormIdEnum;
  yearEndTaxFormId: number;
  formBoxList: FormBoxListItem[];
};

export type EmployeeTaxFormMatrix = TaxFormBase & {
  employmentType: EmployeeTypeEnum;
  formBoxList: {
    displayName: string;
    name: string;
    stringValue: string;
  }[];
};

export type AmendmentTaxForm = TaxFormBase & {
  pdfDocumentId: string;
  isSelected: boolean;
};

export type YearEndDefaultParams = {
  companyId: number;
  year: number;
  craAccountNumber: string;
};

export type YearEndRequest = {
  companyId: number;
  year: number;
  craAccountNumber: string;
  contractorIdList: number[];
  package: {
    defaultContactName: string;
    defaultContactNumber: string;
    dentalBenefitsType?: number;
    createT4A?: boolean;
    defaultContactAreaCode: string;
    defaultContactCountryCode?: string;
  };
};

export type YearEndPreference = {
  yearEndSubmissionType: SubmissionStatusTypeEnum;
  yearEndViewType: ViewTypeEnum;
};

export type FormRL1BoxListItemName =
  | 'A'
  | 'B'
  | 'C'
  | 'D'
  | 'E'
  | 'F'
  | 'G'
  | 'H'
  | 'I'
  | 'J'
  | 'K'
  | 'L'
  | 'M'
  | 'N'
  | 'O'
  | 'O-CB'
  | 'O-RJ'
  | 'O-RM'
  | 'Ocode'
  | 'P'
  | 'Q'
  | 'R'
  | 'S'
  | 'T'
  | 'U'
  | 'V'
  | 'W'
  | 'amount1'
  | 'amount2'
  | 'amount3'
  | 'amount4'
  | 'amount5'
  | 'amount6'
  | 'amount7'
  | 'amount8'
  | 'boxCase1'
  | 'boxCase2'
  | 'boxCase3'
  | 'boxCase4'
  | 'boxCase5'
  | 'boxCase6'
  | 'boxCase7'
  | 'boxCase8'
  | 'code'
  | 'oldsq'
  | 'rl1-Address'
  | 'rl1-Branch'
  | 'rl1-CityProv'
  | 'rl1-EENbr'
  | 'rl1-ERAddress'
  | 'rl1-ERCityProv'
  | 'rl1-ERName'
  | 'rl1-ERPostalCode'
  | 'rl1-Name'
  | 'rl1-PostalCode'
  | 'rl1-SIN1'
  | 'rl1-SIN2'
  | 'rl1-SIN3'
  | 'sqonline'
  | 'sqpdf'
  | 'temp';

export type FormT4BoxListItemName =
  | 'box14'
  | 'box24'
  | 'box26'
  | 'box10'
  | 'box28a'
  | 'box28b'
  | 'box28c'
  | 'box29'
  | 'box16'
  | 'box17'
  | 'box18'
  | 'box20'
  | 'box52'
  | 'box55'
  | 'box22'
  | 'box44'
  | 'box46'
  | 'box50'
  | 'box56'
  | 'lastName'
  | 'firstName'
  | 'middleInitial'
  | 'address1'
  | 'city'
  | 'state'
  | 'postal';

export type FormT4ABoxListItemName =
  | 'box016'
  | 'box022'
  | 'box018'
  | 'box020'
  | 'box024'
  | 'box048'
  | 'payrollAcctNbr'
  | 'SIN'
  | 'acctNbr'
  | 'lastName'
  | 'firstName'
  | 'middleInitial'
  | 'address1'
  | 'city'
  | 'state'
  | 'postal';

export type FormBoxListItem = {
  name: string;
  stringValue: string | string[];
};

export type YearEndTaxForm = {
  allowDelete: boolean;
  formBoxList: FormBoxListItem[];
  formStatusTypeId: number;
  id: FormIdEnum;
  isRevertedToOrginal?: boolean;
  isModified: boolean;
  name: string;
  t4aExempted: boolean;
  updatedDate?: string;
  updatedBy?: string;
};

export type TaxFormRequest = {
  id: FormIdEnum;
  formBoxList: FormBoxListItem[];
};

export type YearEndFormRequest = {
  yearEndTaxFormId: number;
  form?: TaxFormRequest;
};

export type SummaryFormRequest = {
  companyId: number;
  yearEndPackageId: number;
  formId: FormIdEnum;
  form?: TaxFormRequest;
};

export enum DiscrepancyTypeEnum {
  notSpecified = 0,
  cpp = 1,
  ei = 2,
  cpp2 = 3,
}

export type Employee = {
  id: number;
  name: string;
};

type CompanyLocation = {
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
};

export type Team = {
  missingEmailList: Employee[];
  notLoggedInList: Employee[];
  missingAddressList: Employee[];
  invalidSocialList: Employee[];
};

export type CompanyDetails = {
  name: string;
  craNumber: string;
  rqNumber: string;
  headQuarterAddress: CompanyLocation;
};

export type Discrepancy = {
  employeeId: number;
  employeeName: string;
  contribution: number;
  deficiency: string;
  isOverpayment: boolean;
  discrepancyType: DiscrepancyTypeEnum;
};

export type ConfirmationFlagsData = {
  confirmEarningDiscrepancies: boolean;
  confirmEmployerDetails: boolean;
  confirmEmployeeDetails: boolean;
  confirmTerminated: boolean;
  inviteTeam: boolean;
};

export type ConfirmationFieldName = keyof ConfirmationFlagsData;

export type CheckBoxOnChangeHandler = (
  fieldName: ConfirmationFieldName,
  value: boolean,
  options: {
    errorCallback?: () => void;
    successCallback?: () => void;
  }
) => void;

export interface IGetTaxAccountNumbersRequest {
  companyId: number;
  taxYear: number;
}

export interface IGetTaxYearsRequestInterface {
  companyId: number;
  effectiveDate: string;
}

export interface IGetEmployeeTaxFormsListRequestInterface {
  companyId: number;
  employeeNameStartsWith?: string;
  formId?: FormIdEnum;
  getByEmployeeName?: boolean;
  getByFormId?: boolean;
  getTotalCount?: boolean;
  yearEndPackageId: number;
}

export interface IGetAmendmentTaxFormsRequest {
  yearEndPackageId?: number;
  companyId?: number;
  taxYear?: number;
  craAccountNumber?: string;
}

export interface IGetAmendmentTaxFormsResponse {
  amendmentTaxFormList: AmendmentTaxForm[];
  validationResults: IValidationResults;
}

export interface IPersistAmendmentPackageRequest {
  companyId?: number;
  yearEndPackageId?: number;
  taxYear?: number;
  craAccountNumber?: string;
  effectiveDate: string;
  employeeTaxFormList: EmployeeTaxForm[];
}

export interface IPersistAmendmentPackageResponse {
  yearEndPackageId: number;
  validationResults: IValidationResults;
}

export interface IPersistPackageRequest {
  confirmedAccuracy: boolean;
  yearEndPackageId: number;
  submissionType: SubmissionStatusTypeEnum;
}

export interface IPersistPackageResponse {
  validationResults: IValidationResults;
}

export interface IPersistChecklistRequestInterface extends YearEndDefaultParams {
  lastCompletedGoodCheck?: string;
  notification1Sent?: boolean;
  notification2Sent?: boolean;
  rqAccountNumber?: string;
  checkListCompletedDate?: string;
  confirmation: ConfirmationFlagsData;
}

export interface IPersistChecklistResponseInterface {
  validationResults: IValidationResults;
}

export interface IGetInitialStateRequestInterface {
  companyId: number;
  taxYear: number;
  craAccountNumber: string;
  effectiveDate: string;
}

export interface IContractorList {
  firstName: string;
  governmentNumber: string;
  id: number;
  lastName: string;
  lastPaidDate: string;
}

export interface IGetInitialStateResponseInterface {
  validationResults: IValidationResults;
  yearEnd: {
    allowRunChecklist: boolean;
    allowPrepareForms: boolean;
    allowAmendments: boolean;
    checkListCompletedDate?: string;
    confirmation: ConfirmationFlagsData;
    id: number;
    isPenaltyApplicable: boolean;
    showCreateT4ACard: boolean;
  };
  contractorList: IContractorList;
  hasPaidEmployee: boolean;
}

export interface IGetChecklistResponseInterface {
  checkListComplete: boolean;
  yearList: number[];
  team: Team;
  earningDiscrepancyList: Discrepancy[];
  confirmation: ConfirmationFlagsData;
  companyDetails: CompanyDetails;
  validationResults: IValidationResults;
}

export interface IGetEmployeeTaxFormsListResponse {
  totalCount: number;
  employeeTaxFormList: EmployeeTaxForm[];
  yearEndFileList: Array<YearEndFileInterface>;
  validationResults: IValidationResults;
}

export interface YearEndFileInterface {
  id: number;
  explicitFormType: ExplicitFormTypeEnum;
  confirmationCode: string;
  creationDate: string;
  name: string;
  filedDate: string;
}

export interface IMaintainPreferenceParams {
  yearEndPackageId: number;
  preference: YearEndPreference;
}

export interface IMaintainPreferenceResponse extends ResponseValidationResults {
  preference: YearEndPreference;
}

export interface IPrepareYearEndFormsResponse {
  yearEndPackageId: number;
}

export interface IGetYearEndPackageRequest {
  yearEndPackageId: number;
}

export interface IGetYearEndPackageResponse extends ResponseValidationResults {
  package: YearEndPackage;
}

export interface IGetYearEndPackageListResponse {
  packageList: YearEndPackage[];
  validationResults: IValidationResults;
}

export interface ITaxFormResponse {
  validationResults: IValidationResults;
  form: YearEndTaxForm;
}

export interface IGetTaxYearsResponse {
  taxYearList: { year: number }[];
  validationResults: IValidationResults;
}

export interface IGetTaxAccountNumbersResponse {
  taxAccountNumberList: { craAccountNumber: string }[];
  validationResults: IValidationResults;
}

export interface IGetSummaryFormRequest {
  yearEndPackageId: number;
  formId: FormIdEnum;
  companyId: number;
}

export interface IGetSummaryFormResponse extends ResponseValidationResults {
  form: YearEndTaxForm;
}

export interface IGetSummaryListRequest {
  yearEndPackageId: number;
  summaryFormIds?: number[];
}

export interface IGetSummaryListResponse {
  yearEndPackageName: string;
  yearEndPackageStatusType: PackageStatusTypeEnum;
  summaryList: SummaryListItem[];
  confirmedAccuracy: boolean;
  craAccountNumber: string;
  validationResults: ValidationResultsInterface;
}

export type SummaryListItem = {
  formId: FormIdEnum;
  summaryDocumentId?: number;
  summaryItemList: FormBoxListItem[];
};

export interface IMaintainFormMatrixRequest {
  companyId: number;
  employeeIds?: number[];
  formBoxNames?: string[];
  formId: FormIdEnum;
  yearEndPackageId: number;
  employeeTaxFormList?: EmployeeTaxFormMatrixRequest[];
}

export type IMaintainFormMatrixResponse = ResponseWithErrors<{
  employeeTaxFormList: EmployeeTaxFormMatrix[];
}>;

export interface IGetOtherInformationFormBoxesRequest {
  formId: FormIdEnum;
}

export type FormBox = {
  id: number;
  name: string;
  alternateName: string;
  displayName: string;
};

export type SummaryAdditionalFormBoxName = 'box82' | 'total26';

export interface IGetOtherInformationFormBoxesResponse {
  otherInformationFormBoxList: FormBox[];
}

export interface IPrepareTaxSummaryRequest {
  yearEndPackageId: number;
  formId: FormIdEnum;
  isSaveAndExit: boolean;
}

export interface IPersistSummaryAdditionalBoxRequest {
  yearEndPackageId: number;
  formId: number;
  companyId: number;
  formBox: {
    displayName?: string;
    name: SummaryAdditionalFormBoxName;
    stringValue: string;
  };
}

export interface IValidationResultsResponse {
  validationResults: IValidationResults;
}

export interface DownloadCSVRequest {
  formId: number;
  yearEndPackageId: number;
  employeeIds: number[];
  formBoxNames?: string[];
  companyId: number;
}

export type DownloadCSVResponse = ResponseWithErrors<{
  byteArray: string;
}>;

export type DownloadPackageRequest = {
  yearEndPackageId: number;
  companyId: number;
};

export type DownloadPackageResponse = ResponseWithErrors<{
  documentId: number;
}>;

export type IGetEarningDiscrepancyPdfResponse = ResponseWithErrors<{
  pdfContent: string;
}>;

export interface IGetContractorListResponse {
  hasPaidEmployee: boolean;
  contractorList: IContractorList[];
  isT4aAlreadyCreated: boolean;
}

export interface IGetContractorListRequest {
  companyId: number;
  taxYear: number;
  craAccountNumber?: string;
}
