import { CompanyTypeEnum } from 'services/appCore/models/schema/Schema';
import { SecurityRoleTypeEnum } from 'services/shared/securityRoleTypeEnum';
import { TimeZoneTypeEnum } from 'services/shared/timeZoneTypeEnum';
import { IValidationResult } from 'services/shared/validationResults.contracts';
import { SubscriptionPeriodInterface } from 'shared/services/payroll/uiConfigAccess';
import { CompanyStatusTypeEnum } from 'shared/services/appCore/schema';
import { CompanyTypeUiEnum } from '@wagepoint/ui-shared-library/shared-system/services/payroll/models/access/uiConfigAccess';

export interface IUserDetail {
  firstName: string;
  lastName: string;
  cultureType: number;
  country?: { id: number; name: string };
  userId?: number; // please note this key is currently used by notification page for now
  tenantId: number;
  emailAddress?: string;
  companyName: string;
  companyCode: string;
  companyType: CompanyTypeEnum;
  personId: number;
  dontShowMeFlags: number;
  timeZoneType: number;
  avatarDocumentId: number;
  isEmployee: boolean;
  isContractor: boolean;
  isAdmin: boolean;
  accountOwnerName: string;
  securityRoleType: SecurityRoleTypeEnum;
}

export interface IMenu {
  id: number;
  name?: string;
  icon?: string;
  tooltip?: string;
  customClass?: string;
  description: string;
  url: string;
  key: string;
  isVisible: boolean;
  isDisabled: boolean;
  isViewOnly: boolean;
  menuItemList: Array<IMenu>;
  subMenuList?: Array<IMenu>;
}

export enum CultureTypeEnum {
  NotDefined = 0,
  EnglishUs = 1,
  EnglishCa = 2,
  FrenchCa = 3,
}

export enum ApplicationTypeEnum {
  Wagepoint = 0,
  EmployeePortal = 1,
  Alfred = 2,
  AccountingDashboard = 3,
}

export enum CompanyViewTypeEnum {
  notDefined = 0,
  customer = 1,
  partner = 2,
}
export interface IGetConfigurationRequest {
  applicationType: number;
  companyViewType?: CompanyViewTypeEnum;
}

export interface IAuthUserConfig {
  page: string;
  application: string;
  menuList: Array<IMenu>;
  userDetail: IUserDetail;
  companyStatusType?: number;
}
export interface IGetConfigurationResponse {
  page: string;
  application: string;
  companyStatusType?: number;
  hasAssociatedCompanys: boolean;
  landingPageType: number;
  menuList: Array<IMenu>;
  userDetail: IUserDetail;
  logoId?: number;
  isPartnerFirm: boolean;
  useLogoAsFavicon?: boolean;
  wagepointCompanySettingsList: Array<IWagepointCompanySettings>;
  validationResults: Array<IValidationResult>;
}

export interface IAppConfig {
  cultureType: number;
  page: string;
  application: string;
  companyStatusType?: CompanyStatusTypeEnum;
  isMigratedCompany?: boolean;
  country: { id: number; name: string };
  userId?: number; // please note this key is currently used by notification page for now
  tenantId: number;
  emailAddress?: string;
  menuList: Array<IMenu>;
  companyName: string;
  companyCode: string;
  companyType: CompanyTypeEnum | CompanyTypeUiEnum;
  personId: number;
  isPartnerFirm: boolean;
  dontShowMeFlags: number;
  firstName?: string;
  lastName?: string;
  logoId?: number;
  useLogoAsFavicon?: boolean;
  securityRoleType: SecurityRoleTypeEnum;
  isEmployee: boolean;
  isContractor: boolean;
  hasAssociatedCompanys: boolean;
  landingPageType: number;
  wagepointCompanySettingsList: Array<IWagepointCompanySettings>;
  timeZoneType?: TimeZoneTypeEnum;
  userNeedsToOptInPlan: boolean;
  subscriptionPeriod: SubscriptionPeriodInterface;
  hasTwoStepApprovalOn?: boolean;
  companySignupDate?: string;
  isPartnerConnected?: boolean;
}
export interface ICompany {
  companyId: number;
  name: string;
  logoId: number;
}

interface IDontShowFlagMigration {
  loading?: boolean;
  value: null | number;
}
interface IMigrationData {
  dontshowFlag: IDontShowFlagMigration;
  signatoryName: {
    name?: string | null;
  };
}
interface IAppConfigExtended extends IAppConfig {
  isTrail: boolean;
  subscriptionPlanName: string;
  partnerCompanyName?: string;
  partnerCompanyId?: number;
  isAccountOwnerEmailVerified?: boolean;
  showMigrationDashboard?: boolean;
  actualCompanyType?: number;
  isPartnerHandlingUpgrade?: boolean;
  isAutoAssignAdminOn?: boolean;
  accountOwnerName: string;
}
export interface IAnnualReportConfig {
  employeeHadYtd: boolean;
  isLoading: boolean;
}
export interface IConfig {
  selectedCompany: Partial<ICompany>;
  migration: IMigrationData;
  config: Partial<IAppConfigExtended>;
  reload?: boolean;
  permissionConfig?: { [x: string]: { isViewOnly?: boolean; isDisabled?: boolean; isVisible?: boolean } };
  showLoader?: boolean;
  isCompanySwitched?: boolean;
  isPartnerInviteModalConfirmed?: boolean;
  loadingDontshowEnum?: boolean;
  showMigrationPartnerAccessModal?: boolean;
  reports?: {
    annualReport?: IAnnualReportConfig;
  };
}

export enum LandingPageTypeEnum {
  NotDefined = 0,
  Payroll = 1,
  People = 2,
  Reports = 3,
  General = 4,
}

export interface IWagepointCompanySettings {
  companySettingType: number;
  isVisible: boolean;
}

export enum WagepointCompanySettingEnum {
  General = 61,
  UserAccounts = 64,
  AssociatedCompany = 82,
  AccountingBookkeepingFirm = 85,
  CloseAccount = 67,
  Notifications = 150,
  Subscription = 183,
}

export enum WagepointMenuTypeEnum {
  CompanyDetails = 16,
  PayrollSettings = 22,
  People = 25,
  TaxSettings = 28,
  Payroll = 31,
}

export enum EmployeeMenuTypeEnum {
  Overview = 101,
  Personal = 110,
  JobPay = 107,
  Reports = 113,
}

export interface IPermissionAction {
  id: number;
  name: string;
  permissionActionParentId: number;
  permissionType: number;
  description: string;
}

export enum IDayFormatter {
  StartofDay = 1,
  EndofDay = 2,
}

export enum SmallBusinessMenuEnum {
  Payroll = 31,
  Reports = 79,
  People = 25,
  Company = 16,
  AddOns = 1,
  Settings = 2,
}

export enum ReportsMenuEnum {
  PayrollRegister = 34,
  PayrollTax = 186,
  PayStubs = 49,
  Earnings = 40,
  Benefits = 43,
  Deductions = 46,
  WorkersCompensation = 37,
  YearToDate = 52,
  PostingJournal = 58,
  DepositSummary = 55,
  PyarollInvoice = 73,
  YearEnd = 94,
}
